import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { Option } from 'types/admin/Option';

export type RestaurantConfigEnumOptions = {
  restaurant_statuses: Option[];
  portals: Option[];
  countries: Option[];
  widget_languages: Option[];
  languages: Option[];
  timezones: Option[];
  michelin_awards: Option[];
  portal_position: Option[];
};

export const getRestaurantConfigEnumOptionsCall =
  async (): Promise<RestaurantConfigEnumOptions> => {
    return await asyncRequest(
      {
        method: 'GET',
        baseURL: baseUrl,
        url: ENDPOINT_URLS.ADMIN.SYSTEM.ENUM_OPTIONS,
      },
      true,
    );
  };
