import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import {
  PeriodOpeningHours,
  UpdateWeekdayOpeningHours,
  WeekdayOpeningHours,
} from 'types/app/openingHours';

export const getOpeningHours = async (): Promise<(WeekdayOpeningHours | PeriodOpeningHours)[]> => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.OPENING_HOURS.ROOT,
  });
};

export const getWeekdayOpeningHours = async (): Promise<WeekdayOpeningHours> => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.OPENING_HOURS.WEEKDAY.INDEX,
  });
};

export const updateMultipleWeekdayOpeningHours = async (
  openingHours: UpdateWeekdayOpeningHours[],
): Promise<null> => {
  return await asyncRequest({
    method: 'PUT',
    baseURL: baseUrl,
    data: {
      opening_hours: openingHours,
    },
    url: ENDPOINT_URLS.RESTAURANT.OPENING_HOURS.WEEKDAY.INDEX,
  });
};

export const getPeriodOpeningHours = async (): Promise<PeriodOpeningHours[]> => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.OPENING_HOURS.PERIOD.INDEX,
  });
};
