import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { PaginatedResponse } from 'types/app/paginatedResponse';
import { AdminWidgetGroup, AdminWidgetTheme } from 'types/admin/WidgetIntegration';

type QueryParams = Record<string, string>;

export const getAdminWidgetThemeGroupIndexCall = async (
  queryParams?: QueryParams,
): Promise<PaginatedResponse<AdminWidgetGroup[]>> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME_GROUPS.ROOT,
      params: queryParams,
    },
    true,
  );
};

export const getOneAdminWidgetThemeGroupCall = async (id: number): Promise<AdminWidgetGroup> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME_GROUPS.ROOT}/${id}`,
    },
    true,
  );
};

export const createAdminWidgetThemeGroupCall = async (
  data: Omit<AdminWidgetGroup, 'id'>,
): Promise<AdminWidgetGroup> => {
  return await asyncRequest(
    {
      method: 'POST',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME_GROUPS.ROOT,
      data: data,
    },
    true,
  );
};

export const updateAdminWidgetThemeGroupCall = async (
  id: number,
  data: Omit<AdminWidgetGroup, 'id'>,
): Promise<AdminWidgetGroup> => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME_GROUPS.ROOT}/${id}`,
      data: data,
    },
    true,
  );
};

export const deleteAdminWidgetThemeGroupCall = async (id: number): Promise<null> => {
  return await asyncRequest(
    {
      method: 'DELETE',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME_GROUPS.ROOT}/${id}`,
    },
    true,
  );
};

export const getAdminWidgetThemeIndexCall = async (
  queryParams?: QueryParams,
): Promise<PaginatedResponse<AdminWidgetGroup[]>> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME.ROOT,
      params: queryParams,
    },
    true,
  );
};

export const getOneAdminWidgetThemeCall = async (id: number): Promise<AdminWidgetTheme> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME.ROOT}/${id}`,
    },
    true,
  );
};

export const createAdminWidgetThemeCall = async (
  data: Omit<AdminWidgetTheme, 'id'>,
): Promise<AdminWidgetTheme> => {
  return await asyncRequest(
    {
      method: 'POST',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME.ROOT,
      data: data,
    },
    true,
  );
};

export const updateAdminWidgetThemeCall = async (
  id: number,
  data: Omit<AdminWidgetTheme, 'id'>,
): Promise<AdminWidgetTheme> => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME.ROOT}/${id}`,
      data: data,
    },
    true,
  );
};

export const deleteAdminWidgetThemeCall = async (id: number): Promise<null> => {
  return await asyncRequest(
    {
      method: 'DELETE',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.WIDGET_THEMES.THEME.ROOT}/${id}`,
    },
    true,
  );
};
