import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getAdminLegacyQueueItemsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.APPLICATION.CRON.LEGACY_QUEUE,
    },
    true,
  );
};

export const getAdminLegacyFailedQueueItemsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.APPLICATION.CRON.LEGACY_FAILED_QUEUE,
    },
    true,
  );
};

export const forceRunAdminLegacyQueueCall = async () => {
  return await asyncRequest(
    {
      method: 'POST',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.APPLICATION.CRON.LEGACY_FAILED_QUEUE}/force-run`,
    },
    true,
  );
};

export const deleteAdminLegacyQueueItemCall = async (jobId: number) => {
  return await asyncRequest(
    {
      method: 'DELETE',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ADMIN.APPLICATION.CRON.LEGACY_FAILED_QUEUE}/${jobId}`,
    },
    true,
  );
};

export const getAdminMailQueueItemsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.APPLICATION.CRON.MAIL_QUEUE,
    },
    true,
  );
};

export const getAdminScheduleJobItemsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.APPLICATION.CRON.SCHEDULE_JOBS,
    },
    true,
  );
};
