import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { BookingFormData } from 'types/app/booking';
import { UpdateReservationTime } from 'types/app/reservations';

export interface IGetReservationSendData {
  start_time: number;
  end_time: number;
}

export const getBaseReservationsCall = async ({
  start_time,
  end_time,
}: IGetReservationSendData) => {
  return await asyncRequest({
    method: 'GET',
    params: {
      start_time,
      end_time,
    },
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.RESERVATION.RESERVATIONS.ROOT,
  });
};

let fullReservationsController: AbortController | null = null;

export const getFullReservationsCall = async ({
  start_time,
  end_time,
}: IGetReservationSendData) => {
  if (fullReservationsController) {
    fullReservationsController.abort();
  }

  fullReservationsController = new AbortController();

  return await asyncRequest({
    method: 'GET',
    signal: fullReservationsController.signal,
    params: {
      start_time,
      end_time,
    },
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.RESERVATION.RESERVATIONS.DETAILS,
  });
};

export const createReservationCall = async (data: BookingFormData) => {
  return await asyncRequest({
    method: 'POST',
    data: {
      ...data,
    },
    baseURL: baseUrl,
    url: ENDPOINT_URLS.RESTAURANT.RESERVATION.ROOT,
  });
};

export const getReservationCall = async (reservationId: number) => {
  return await asyncRequest({
    method: 'GET',
    baseURL: baseUrl,
    url: `${ENDPOINT_URLS.RESTAURANT.RESERVATION.ROOT}/${reservationId}`,
  });
};

export const updateReservationCall = async (id: number, data: BookingFormData) => {
  return await asyncRequest({
    method: 'PUT',
    data: {
      ...data,
    },
    baseURL: baseUrl,
    url: `${ENDPOINT_URLS.RESTAURANT.RESERVATION.ROOT}/${id}`,
  });
};

export const changeReservationTimeCall = async (data: UpdateReservationTime) => {
  return await asyncRequest({
    method: 'PUT',
    data: {
      start: data.start,
      end: data.end,
      tables: data.tables,
    },
    baseURL: baseUrl,
    url: `${ENDPOINT_URLS.RESTAURANT.RESERVATION.ROOT}/${data.id}/change-time`,
  });
};

export const deleteReservationCall = async (id: number) => {
  return await asyncRequest({
    method: 'DELETE',
    baseURL: baseUrl,
    url: `${ENDPOINT_URLS.RESTAURANT.RESERVATION.ROOT}/${id}`,
  });
};
