import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getAdminSystemWidgetGroupNamesCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.SYSTEM.WIDGET_ROOT,
    },
    true,
  );
};
